import React, {Component} from "react"
import London from 'components/Pages/HomePage/London'
import Leeds from 'components/Pages/HomePage/Leeds'
import Glasgow from 'components/Pages/HomePage/Glasgow'
import Sunderland from 'components/Pages/HomePage/Sunderland'
import Southampton from 'components/Pages/HomePage/Southampton'
import Edinburgh from 'components/Pages/HomePage/Edinburgh'
// import Hull from 'components/Pages/HomePage/Hull'
import Dublin from 'components/Pages/HomePage/Dublin'
import Aberdeen from 'components/Pages/HomePage/Aberdeen'
import Norwich from 'components/Pages/HomePage/Norwich'
import Cardiff from 'components/Pages/HomePage/Cardiff'
import Newcastle from 'components/Pages/HomePage/Newcastle'
import Nottingham from 'components/Pages/HomePage/Nottingham'
import Zurich from 'components/Pages/HomePage/Zurich'
import Aarhus from 'components/Pages/HomePage/Aarhus'
import Manchester from 'components/Pages/HomePage/Manchester'
import Amsterdam from 'components/Pages/HomePage/Amsterdam'
import Birmingham from 'components/Pages/HomePage/Birmingham'
import Bristol from 'components/Pages/HomePage/Bristol'
import Plymouth from 'components/Pages/HomePage/Plymouth'
import Bradford from 'components/Pages/HomePage/Bradford'
import Hull from 'components/Pages/HomePage/Hull'
import Helmet from "react-helmet";
import Liverpool from "../components/Pages/HomePage/Liverpool"

class HomeTemplate extends Component {
    render() {
        const data = this.props.data
        const {siteversion} = this.props
        return (
            <>
                {data.siteId === 'london' &&
                <London

                    phase={data.phase}
                    siteversion={siteversion}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'glasgow' &&
                <Glasgow
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }
                {data.siteId === 'sunderland' &&
                <Sunderland
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'leeds' &&
                <Leeds
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'manchester' &&
                <Manchester
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {/* {data.siteId === 'birmingham' &&
                <Birmingham
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                } */}
                {data.siteId === 'plymouth' &&
                <Plymouth
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {/* {data.siteId === 'bradford' &&
                <Bradford
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                } */}

                {data.siteId === 'norwich' &&
                <Norwich
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'cardiff' &&
                <Cardiff
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'edinburgh' &&
                <Edinburgh
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                 {data.siteId === 'hull' &&
                <Hull
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }
                 {data.siteId === 'liverpool' &&
                 
                 <Liverpool
                 phase={data.phase}
                 siteId={data.siteId}
                 language={data.language}
                 multiLang={data.multiLang}
             />
                }

                {data.siteId === 'dublin' &&
                <Dublin
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'southampton' &&
                <Southampton
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'aberdeen' &&
                <Aberdeen
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'bristol' &&
                <Bristol
                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'newcastle' &&
                <Newcastle

                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'nottingham' &&
                <Nottingham

                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }

                {data.siteId === 'zurich' &&
                <Zurich

                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }
                {data.siteId === 'aarhus' &&
                <Aarhus

                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }
                {data.siteId === 'amsterdam' &&
                <Amsterdam

                    phase={data.phase}
                    siteId={data.siteId}
                    language={data.language}
                    multiLang={data.multiLang}
                />
                }
            </>
        )
    }
}

export default HomeTemplate 